import "./LineUp.css";
import {Col, Row, Timeline} from "antd";
import TimelineItem from "antd/es/timeline/TimelineItem";
import {FireTwoTone} from "@ant-design/icons";
import {useCallback, useEffect, useMemo, useRef, useState} from "react";
import useBreakpoint from "antd/es/grid/hooks/useBreakpoint";
import YouTube from "react-youtube";


const commonLineUp = [
    {key: "cl1", iconColor: "#fa8c16", label: "18u30", description: "Vertrek fakkeltocht"},
    {key: "cl2", iconColor: "#fa8c16", label: "19u00", description: "Aansteken Hololool"},
    {key: "cl3", iconColor: "#fa8c16", label: "19u30", description: "Start optredens en kinderdisco"},
    {key: "cl4", iconColor: "#fa8c16", label: "21u30", description: "Start fuif"},
    {key: "cl5", iconColor: "#fa8c16", label: "21u45", description: "Grandioos vuurwerk"},
    {key: "cl6", iconColor: "#fa8c16", label: "02u00", description: "Einde"},
];

const lineUpAmbianceTent = [
    {key: "al1", iconColor: "#f5222d", label: "19u30", description: "Andersom", videoId: "ExEfZwU6bT4"},
    {key: "al2", iconColor: "#f5222d", label: "22u00", description: "Level Six", videoId: "DN_LiVb2HXU"},
    {key: "al3", iconColor: "#f5222d", label: "00u30", description: "The Pixels", videoId: "wH6W80q5NQo"},
];

const lineUpFuifTent = [
    {key: "fl1", iconColor: "#faad14", label: "21u00", description: "Winnaar DJ Contest"},
    {key: "fl2", iconColor: "#faad14", label: "22u30", description: "DJ Suntax"},
    {key: "fl3", iconColor: "#faad14", label: "00u00", description: "DJ Wintex"},
    {key: "fl4", iconColor: "#faad14", label: "01u00", description: "DJ Gelltrix 'n chezz"}
];

const executeScroll = (currentRef) => {
    currentRef.current.scrollIntoView();
}

const opts = {
    width: '90%',
    playerVars: {
        autoplay: 0,
    },
};

const LineUp = ({activeLineUp = "common", activeBand = "al1"}) => {

    const screens = useBreakpoint();

    const commonLineUpRef = useRef();
    const ambianceLineUpRef = useRef();
    const partyLineUpRef = useRef();
    const videoRef = useRef();

    const [currentBand, setCurrentBand] = useState(activeBand);

    const lineUpDetails = useMemo(() => (lineUpAmbianceTent.filter(lineUp => lineUp.key === currentBand)[0]), [currentBand])

    useEffect(() => {
        if (activeLineUp) {
            if (activeLineUp === "common") {
                if (screens?.xs) {
                    executeScroll(commonLineUpRef);
                }
            } else if (activeLineUp === "ambiance") {
                if (screens?.xs) {
                    executeScroll(ambianceLineUpRef);
                }
            } else if (activeLineUp === "party") {
                if (screens?.xs) {
                    executeScroll(partyLineUpRef);
                }
            }
        }
        }, [activeLineUp, screens])

    const renderVideo = useCallback(() => {
        return (
            lineUpDetails ? <YouTube ref={videoRef} videoId={lineUpDetails?.videoId} opts={opts}/> : <div/>
        )
    }, [lineUpDetails])

    return (
        <div>
            <Row>
                <Col lg={{span: 8}} md={{span: 24}} xs={{span: 24}}>
                    <Row ref={commonLineUpRef} style={{scrollMargin: "97px"}}>
                        <Col span={24}>
                            <div className={"lineUpItem"}>
                                <div className={"commonHeader"}>
                                    <div className={"lineUpItemText"}>Algemeen</div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} className={"lineUpContent"}>
                            <div className={"lineUpWrapper"}>
                                <Timeline mode={"left"} style={{color: "#000000"}}>
                                    {commonLineUp.map(lineUp => {
                                        return (
                                            <TimelineItem key={lineUp.key} dot={<FireTwoTone twoToneColor={lineUp.iconColor}/>} label={lineUp.label}>{lineUp.description}</TimelineItem>
                                        )
                                    })}
                                </Timeline>
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col lg={{span: 8}} md={{span: 24}} xs={{span: 24}}>
                    <Row ref={ambianceLineUpRef} style={{scrollMargin: "97px"}} >
                        <Col span={24}>
                            <div className={"lineUpItem"}>
                                <div className={"ambianceTentHeader"}>
                                    <div className={"lineUpItemText"}>Feesttent by Pas Car Trailers</div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} className={"lineUpContent"}>
                            <div className={"lineUpWrapper"}>
                                <Timeline mode={"left"} style={{color: "#000000"}}>
                                    {lineUpAmbianceTent.map(lineUp => {
                                        return (
                                            <TimelineItem key={lineUp.key} dot={<FireTwoTone twoToneColor={lineUp.iconColor}/>} label={lineUp.label}>
                                                <div style={{cursor: "pointer"}} onClick={() => setCurrentBand(lineUp.key)}>{lineUp.description}</div>
                                            </TimelineItem>
                                        )
                                    })}
                                </Timeline>
                                { renderVideo() }
                            </div>
                        </Col>
                    </Row>
                </Col>
                <Col lg={{span: 8}} md={{span: 24}} xs={{span: 24}}>
                    <Row ref={partyLineUpRef} style={{scrollMargin: "97px"}}>
                        <Col span={24}>
                            <div className={"lineUpItem"}>
                                <div className={"fuifTentHeader"}>
                                    <div className={"lineUpItemText"}>Fuiftent by Geert Vanoppen</div>
                                </div>
                            </div>
                        </Col>
                    </Row>
                    <Row>
                        <Col span={24} className={"lineUpContent"}>
                            <div className={"lineUpWrapper"}>
                                <Timeline mode={"left"} style={{color: "#000000"}}>
                                    {lineUpFuifTent.map(lineUp => {
                                        return (
                                            <TimelineItem key={lineUp.key} dot={<FireTwoTone twoToneColor={lineUp.iconColor}/>} label={lineUp.label}>{lineUp.description}</TimelineItem>
                                        )
                                    })}
                                </Timeline>
                            </div>
                        </Col>
                    </Row>
                </Col>
            </Row>
            <Row>
                <col span={24} style={{backgroundColor: "#434343", height: "10px", width: "100%"}}></col>
            </Row>
        </div>
    )
};

export default LineUp;