import {Col, Row} from "antd";
import "./Home.css";
import PropTypes from 'prop-types';

const Home = ({onBandClick, onInfoTileClick}) => {

    const handleClick = (lineUp, activeBand) => {
        onBandClick(lineUp, activeBand);
    }

    return (
        <div className={"overlay"}>
            <Row>
                <Col lg={{span: 8, order: 1}} md={{span: 12, order: 2}} xs={{span: 24, order: 2}} className={"tileWrapper"}>
                    <div className={"clickableTile"} onClick={() => onInfoTileClick("tombola")}>
                        <div className={"tekenwedstrijd"}>
                            <div className={"tileText"}>Uitslag teken- en tombolawedstrijd</div>
                        </div>
                    </div>
                </Col>
                <Col lg={{span: 8, order: 2}} md={{span: 12, order: 1}} xs={{span: 24, order: 1}} className={"tileWrapper"}>
                    <div className={"tile"}>
                        <div className={"logo"}/>
                    </div>
                </Col>
                <Col lg={{span: 8, order: 4}} md={{span: 12, order: 4}} xs={{span: 24, order: 4}} className={"tileWrapper"}>
                    <div className={"clickableTile"} onClick={() => onInfoTileClick("info")}>
                        <div className={"fakkeltocht"}>
                            {/*<div className={"tileTextFakkeltocht"} style={{textAlign: 'left', width: 'fit-content'}}>Nieuwe route</div>*/}
                            <div className={"tileText"}>Fakkeltocht</div>
                        </div>
                    </div>
                </Col>
                <Col lg={{span: 8, order: 5}} md={{span: 12, order: 5}} xs={{span: 24, order: 5}} className={"tileWrapper"}>
                    <div className={"clickableTile"} onClick={() => handleClick("common")}>
                        <div className={"straattheater"}>
                            <div className={"tileText"}>Straattheater</div>
                        </div>
                    </div>
                </Col>
                <Col lg={{span: 8, order: 6}} md={{span: 12, order: 6}} xs={{span: 24, order: 6}} className={"tileWrapper"}>
                    <div className={"clickableTile"} onClick={() => handleClick("ambiance", "al1")}>
                        <div className={"andersom"}/>
                    </div>
                </Col>
                <Col lg={{span: 8, order: 7}} md={{span: 12, order: 7}} xs={{span: 24, order: 7}} className={"tileWrapper"}>
                    <div className={"clickableTile"} onClick={() => handleClick("ambiance", "al2")}>
                        <div className={"levelsix"}/>
                    </div>
                </Col>
                <Col lg={{span: 8, order: 8}} md={{span: 12, order: 8}} xs={{span: 24, order: 8}} className={"tileWrapper"}>
                    <div className={"clickableTile"} onClick={() => handleClick("ambiance", "al3")}>
                        <div className={"thepixels"}>
                            <div className={"tileText"}>The Pixels</div>
                        </div>
                    </div>
                </Col>
                <Col lg={{span: 8, order: 9}} md={{span: 12, order: 9}} xs={{span: 24, order: 9}} className={"tileWrapper"}>
                    <div className={"clickableTile"} onClick={() => handleClick("common")}>
                        <div className={"kinderdisco"}>
                            <div className={"tileText"}>Kinderdisco</div>
                        </div>
                    </div>
                </Col>
                <Col lg={{span: 8, order: 10}} md={{span: 12, order: 10}} xs={{span: 24, order: 10}} className={"tileWrapper"}>
                    <div className={"clickableTile"} onClick={() => handleClick("party")}>
                        <div className={"fuiftent"}>
                            <div className={"tileText"}>Fuif</div>
                        </div>
                    </div>
                </Col>
                <Col lg={{span: 8, order: 11}} md={{span: 12, order: 11}} xs={{span: 24, order: 11}} className={"tileWrapper"}>
                    <div className={"clickableTile"} onClick={() => handleClick("common")}>
                        <div className={"firework"}>
                            <div className={"tileText"}>Grandioos Vuurwerk</div>
                        </div>
                    </div>
                </Col>
            </Row>
            <Row>
                <col span={24} style={{backgroundColor: "#434343", height: "10px", width: "100%"}}></col>
            </Row>
        </div>
    )
};

Home.propTypes = {
    onBandClick: PropTypes.func.isRequired,
    onInfoTileClick: PropTypes.func.isRequired
}

export default Home;