import "./Tonbola.css";
import {Col, Row, Table, Typography} from "antd";

const {Title, Text} = Typography;

const tombolaCol = [
    {title: 'Prijs', dataIndex: 'priceNr', key: 'priceNr'},
    {title: 'Lotje', dataIndex: 'lot', key: 'lot'},
];

const tekenCol = [
    {title: 'Prijs', dataIndex: 'priceNr', key: 'priceNr'},
    {title: 'Naam', dataIndex: 'name', key: 'name'},
    {title: 'School', dataIndex: 'school', key: 'priceNr'+123},
];

const tombolaDs = [
    {priceNr: '1ste', lot: '4583'},
    {priceNr: '2de', lot: '6947'},
    {priceNr: '3de', lot: '1862'},
    {priceNr: '4de', lot: '4524'},
    {priceNr: '5de', lot: '4632'},
    {priceNr: '6de', lot: '2744'},
    {priceNr: '7de', lot: '2413'},
    {priceNr: '8ste', lot: '0225'},
    {priceNr: '9de', lot: '1720'},
    {priceNr: '10de', lot: '0371'},
]

const tekenDs = [
    {priceNr: 'Efteling', name: 'Janne Quintiens', school: 'De Horizon'},
    {priceNr: 'Kleuters', name: 'Sam Rutten', school: 'De Kleurdoos'},
    {priceNr: '1ste graad', name: 'Amaluna Guammad', school: 'De Lettermolen'},
    {priceNr: '2de graad', name: 'Emma Vaes', school: 'De St@rtbaan'},
    {priceNr: '3de graad', name: 'Kasper van den Broeck', school: 'De Toverfluit'},
]

const Tekenwedstrijd = () => {
    return (
        <div className={"background"}>
            <Row>
                <Col lg={{span: 12, offset: 6}} md={{span: 24}} xs={{span: 24}} className={"tombolaTile"}>
                    <Title>Uitslag tombolawedstrijd</Title>
                    <Table dataSource={tombolaDs} columns={tombolaCol} pagination={false} style={{width: '80%', margin: 'auto'}}/>
                </Col>
            </Row>
            <Row>
                <Col lg={{span: 12, offset: 6}} md={{span: 24}} xs={{span: 24}} className={"tombolaTile"}>
                    <Title>Uitslag tekenwedstrijd</Title>
                    <Table dataSource={tekenDs} columns={tekenCol} pagination={false} style={{width: '80%', margin: 'auto'}}/>
                </Col>
            </Row>
            <Row>
                <Col lg={{span: 12, offset: 6}} md={{span: 24}} xs={{span: 24}} className={"tombolaTile"}>
                    <Title>Afhalen prijzen</Title>
                    <Text>Bovenstaande prijzen zijn af te halen na telefonische afspraak, voor 20 december op nr.: 0477 657 687</Text>
                </Col>
            </Row>
            <Row>
                <col span={24} style={{backgroundColor: "#434343", height: "10px", width: "100%"}}></col>
            </Row>
        </div>
    )
};

export default Tekenwedstrijd;