import {Carousel, Col, Layout, Row} from "antd";
import {Content, Footer, Header} from "antd/es/layout/layout";
import './MainLayout.css';
import {useCallback, useEffect, useState} from "react";
import Home from "./pages/Home";
import LineUp from "./pages/LineUp";
import Info from "./pages/Info";
import {FacebookFilled, InstagramFilled, TwitterOutlined} from "@ant-design/icons";
import History from "./pages/History";
import {useSearchParams} from "react-router-dom";
import Tekenwedstrijd from "./pages/Tekenwedstrijd";
import Tombola from "./pages/Tombola";
import Sponsors from "./pages/Sponsors";

import logo from '../logo.png';
import aansteken from '../images/event/aansteken.jpg';
import kinderdisco from '../images/event/kinderdisco.jpg';
import fuiftent from '../images/event/fuiftent.jpg';

const underConstruction = true;

const MainLayout = () => {

    const onHomeBandClick = useCallback((lineUp, activeBand) => {
        setCurrentPage(<LineUp activeLineUp={lineUp} activeBand={activeBand}/>)
        setActiveMenu("lineUp");
    }, [])

    const onInfoTileClick = useCallback((page) => {
        if (page === 'tekenwedstrijd') {
            setCurrentPage(<Tekenwedstrijd/>)
            window.scrollTo(0,0)
        } else if (page === 'tombola') {
            setCurrentPage(<Tombola/>)
            window.scrollTo(0,0)
        } else if (page === 'info') {
            handleMenuClick(<Info/>, "info");
        }
    }, [])

    const [currentPage, setCurrentPage] = useState(<Home onBandClick={onHomeBandClick} onInfoTileClick={onInfoTileClick}/>);
    const [activeMenu, setActiveMenu] = useState("home");
    const [searchParams, setSearchParams] = useSearchParams();

    useEffect(() => {
        const page = searchParams.get("main");
        if (page === 'tekenwedstrijd') {
            setCurrentPage(<Tekenwedstrijd/>)
        } else if (page === 'tombola') {
            setCurrentPage(<Tombola/>)
        }
        setSearchParams();
    }, [searchParams])

    const handleMenuClick = useCallback((newPage, menuName) => {
        setCurrentPage(newPage);
        setActiveMenu(menuName)
        window.scrollTo(0,0)
    }, [])

    const getMenuClassName = useCallback((menuName) => {
        return menuName === activeMenu ?  "activeMenuItem" : "menuItem";
    }, [])

    const renderMainSite = () => {
        return <>
            <Header className={'header'}>
                <Row>
                    <Col xl={1} lg={2} md={6} xs={6} onClick={() => handleMenuClick(<Home onBandClick={onHomeBandClick} onInfoTileClick={onInfoTileClick}/>, "home")} className={getMenuClassName("home")}>HOME</Col>
                    <Col xl={1} lg={2} md={6} xs={6} onClick={() => handleMenuClick(<LineUp/>, "lineUp")} className={getMenuClassName("lineUp")}>LINE-UP</Col>
                    <Col xl={1} lg={2} md={6} xs={6} onClick={() => handleMenuClick(<Info/>, "info")} className={getMenuClassName("info")}>INFO</Col>
                    <Col xl={1} lg={2} md={6} xs={6} onClick={() => handleMenuClick(<History/>, "history")} className={getMenuClassName("history")}>HISTORY</Col>
                    {/*<Col onClick={() => handleMenuClick(<Contact/>)} style={{cursor: "pointer"}}>CONTACT</Col>*/}
                </Row>
            </Header>
            <Content style={{marginTop: "64px"}}>
                <Row className={"infoRow"}>
                    <Col span={8} className={"info"}>VRIJ 10 NOV 2023</Col>
                    <Col span={8} className={"info"}>AANSTEKEN HOLOLOOL - 19U</Col>
                    <Col span={8}>
                        <Row className={"socials"}>
                            <Col className={"socialCol"}>
                                <a href={"https://www.facebook.com/HololoolVuurfestival"} target={"_blank"} style={{color: "white"}}>
                                    <FacebookFilled style={{fontSize: "20px", cursor: "pointer"}}/>
                                </a>
                            </Col>
                            <Col className={"socialCol"}>
                                <a href={"https://www.instagram.com/hololool_fest/"} target={"_blank"} style={{color: "white"}}>
                                    <InstagramFilled style={{fontSize: "20px", cursor: "pointer"}}/>
                                </a>
                            </Col>
                            <Col className={"socialCol"}>
                                <a href={"https://twitter.com/hololool_fest"} target={"_blank"} style={{color: "white"}}>
                                    <TwitterOutlined style={{fontSize: "20px", cursor: "pointer"}}/>
                                </a>
                            </Col>
                        </Row>
                    </Col>
                </Row>
                <Row style={{marginTop: "33px"}}>
                    <Col span={24}>
                        <div className={'body'}>
                            {currentPage}
                        </div>
                    </Col>
                </Row>
            </Content>
            <Footer className={'sponsors'} style={{textAlign: "center"}}>
                <p><span style={{fontFamily: "Vivaldi", fontSize: "24px"}}>Hololool Vuurfestival</span></p>
                <p>i.s.m.: Chiro Zonhoven Centrum, KSA Zonhoven, Chiro Meisjes & Jongens Halveweg</p>
                <p>Met de steun van</p>
                <Sponsors/>
            </Footer>
        </>
    }

    const renderUnderConstruction = () => {
        return <header className={'App-header'}>
            <Carousel dots={false} autoplay style={{width: "400px"}}>
                <div>
                    <img src={logo} alt={"hololool"} style={{width: "400px"}}/>
                </div>
                <div>
                    <img src={aansteken} alt={"hololool"} style={{width: "400px"}}/>
                </div>
                <div>
                    <img src={kinderdisco} alt={"hololool"} style={{width: "400px"}}/>
                </div>
                <div>
                    <img src={fuiftent} alt={"hololool"} style={{width: "400px"}}/>
                </div>
            </Carousel>
            <h2 style={{color: "white"}}>Zondag 10 november 2024</h2>
            <h3 style={{color: "white"}}>@ Park De Dijkbeemden, Zonhoven</h3>
        </header>
    }

    return (
        <Layout className={'layout'}>
            {underConstruction ? renderUnderConstruction() : renderMainSite()}
            <Footer className={'footer'}>
                © {new Date().getFullYear()} Hololool Vuurfestival
            </Footer>
        </Layout>
    );
}

export default MainLayout;